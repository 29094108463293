import { environment } from 'environments/environment';

export const AppConstant = {
  domain: environment.domain,
  apiDomain: environment.domain + '/api',
  clientId: '297bba86-a046-4965-9222-7630a419bf5f',
  clientSecret: '58ba131b-009e-4e77-98a1-9f570bd1dd89',
  pattern: {
    email: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
    whiteSpace: '^\\S+(?: \\S+)*$',
    pin: '[0-9][0-9][0-9][0-9]',
    stageCode: '^CD(0[1-9]|[1-9][0-9])$',
    stageDetailCode: '^CD[0-9]{2}-(0[1-9]|[1-9][0-9])$',
    alphabet: '^[a-z A-Z]*$',
    ipAddress: '^(([01]?[0-9]?[0-9]|2[0-5][0-5])\\.){3}([01]?[0-9]?[0-9]|2[0-5][0-5])$',
    biggerZero: '^[1-9][0-9]*$',
    userName: '^[-_.@a-zA-Z0-9]*$',
    password: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
    datetime: '[\\d]{4}-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-6][0-9]:[\\d]{2}',
    phone: '(84|0[3|5|7|8|9])+([0-9]{8})',
    letterNumberOnly: '^[a-zA-Z0-9]*$',
    ratio: '^1+(?::[1-9][0-9]*){1,2}$',
    ratioThreeLevel: '^1+(?::[1-9][0-9]*)+(?::[1-9][0-9]*)$',
    ratioTwoLevel: '^1+(?::[1-9][0-9]*)$',
    freeText: '[w\\W]*',
    // eslint-disable-next-line @typescript-eslint/quotes
    url: "^http(s)?://[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
  },

  // use for angular date | pipe
  pipe: {
    fullDateTime: 'dd/MM/yyyy HH:mm:ss',
    timeDate: 'HH:mm dd/MM/yyyy',
    dateTime: 'dd/MM/yyyy HH:mm',
    time: 'HH:mm:ss',
    date: 'dd/MM/yyyy',
    shortTime: 'HH:mm',
  },

  // use for ngx-mask
  mask: {
    phone: '(000) 000-0000',
    ppCode: 'A*'
  },
  prefix: {
    productionPlan: 'SX-',
    productionPlanReWork: 'RW-',
  },
  count: {
    valueSecond_30: 30000,
  },
  // use for moment() or dayjs() format
  format: {
    isoDateTime: 'YYYY-MM-DDTHH:mm:ss', // this format use to save db
    isoDate: 'YYYY-MM-DD',
    timeDate: 'HH:mm DD/MM/YYYY',
    dateTime: 'DD/MM/YYYY HH:mm',
    fullDateTime: 'DD/MM/YYYY HH:mm:ss',
    longDateTime: 'DD MMM YYYY, HH:mm:ss',
    time: 'HH:mm:ss',
    shortTime: 'HH:mm',
    dataTime: 'dd/MM/yyyy hh:mm a',
    date: 'DD/MM/YYYY',
  },
  validation: {
    maxLength8: 8,
    maxLength16: 16,
    maxLength25: 25,
    maxLength20: 20,
    maxLength30: 30,
    maxLength32: 32,
    maxLength64: 64,
    maxLength128: 128,
    maxLength256: 256,
    maxLength255: 255,
    maxLength512: 512,
    sheetPrintMax: 9999999,
    compensationMax: 999999.99,
    maxCartonWeight: 65,
    maxNumber: 999,
  },
  appPageSize: 25,
  appStorage: {
    userInfo: 'userInfo',
    userToken: 'userToken',
  },

  unit: {
    milimet: '(mm)',
    percent: '%',
  },
};

export const FilterKey = {
  genericTable: {
    filterData: 'genericTable.filterData',
    displayedColumns: 'genericTable.displayedColumns',
  },
};

export enum ControlType {
  text = 'TextBox',
  numberInput = 'Number',
  select = 'Combobox',
  label = 'Label',
  datePicker = 'DatePicker',
  datetimePicker = 'DatetimePicker',
  checkBox = 'Checkbox',
  radioButton = 'RadioButton',
  button = 'Button',
  pickTimeButton = 'PicktimeButton',
  pickCurrentUser = 'PickCurrentUser',
  autoPickCurrentUser = 'AutoPickCurrentUser',
  textarea = 'Textarea',
  autoNumber = 'AutoNumber',
  autoAlphabet = 'AutoAlphabet',
  scan = 'Scan',
  colorPicker = 'ColorPicker',
  timePicker = 'TimePicker',
  autoDatetime = 'AutoDatetime',
  timeInput = 'TimeInput',
  html = 'Html',
  autoIndex = 'AutoIndex',
  image = 'Image',
  multipleSelect = 'MutipleSelect',
}

export enum ContentType {
  json = 'application/json; charset=utf-8',
  octetStream = 'application/octet-stream',
}

export const HeaderRequestType = {
  importExportRequestType: {
    observe: 'response',
    responseType: 'arraybuffer',
  },
};
