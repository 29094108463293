import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from 'app/core/services/localStorage.service';

@Component({
  selector: 'languages',
  templateUrl: './languages.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'languages',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit, OnDestroy {
  public availableLangs: LangDefinition[];
  public activeLang: string;
  // flagCodes: any;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _translocoService: TranslocoService,
    private _localStorageService: LocalStorageService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this._httpClient
    //   .get<ILanguageResponse>(`${AppConstant.apiDomain}/languages`, {
    //     params: { isActive: true },
    //   })
    //   .pipe(map((resp) => resp.languageList || resp.items || []))
    //   .subscribe((resp) => {
    //     this.availableLangs = resp.map((x) => {
    //       const lang: LangDefinition = { id: x.languageCulture, label: x.name };
    //       return lang;
    //     });
    //     this._translocoService.setAvailableLangs(this.availableLangs);
    //   });
    // Get the available languages from transloco
    this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe((activeLang) => {
      // Get the active lang
      this.activeLang = activeLang;
      this._translocoService.selectTranslation(activeLang).subscribe(() => {
        // Update the navigation
        this._updateNavigation();
      });
    });

    // Set the country iso codes for languages for flags
    // this.flagCodes = {
    //   en: 'us',
    //   vi: 'vn',
    //   tr: 'tr',
    // };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  public setActiveLang(lang: string): void {
    // Set the active lang
    this._translocoService.setActiveLang(lang);
    this._localStorageService.store('lang', lang);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the navigation
   *
   * @param lang
   * @private
   */
  private _updateNavigation(): void {
    // For the demonstration purposes, we will only update the Dashboard names
    // from the navigation but you can do a full swap and change the entire
    // navigation data.
    //
    // You can import the data from a file or request it from your backend,
    // it's up to you.

    // Get the component -> navigation data -> item
    const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

    // Return if the navigation component does not exist
    if (!navComponent) {
      return null;
    }

    // Get the flat navigation data
    const navigation = navComponent.navigation;
    const processFn = (navigationItems: FuseNavigationItem[]): void => {
      navigationItems.forEach((navigationItem) => {
        if (navigationItem.translateTitleKey) {
          navigationItem.title = this._translocoService.translate(navigationItem.translateTitleKey);
        }
        if (navigationItem.translateSubtitleKey) {
          navigationItem.subtitle = this._translocoService.translate(navigationItem.translateSubtitleKey);
        }
        if (navigationItem.badge && navigationItem.badge.translateKey) {
          navigationItem.badge.title = this._translocoService.translate(navigationItem.badge.translateKey);
        }
        if (navigationItem.children && navigationItem.children.length) {
          processFn(navigationItem.children);
        }
      });
    };
    processFn(navigation);
    navComponent.refresh();
    // Get the Project dashboard item and update its title
    // const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
    // if (projectDashboardItem) {
    //   this._translocoService
    //     .selectTranslate('Project')
    //     .pipe(take(1))
    //     .subscribe((translation) => {
    //       // Set the title
    //       projectDashboardItem.title = translation;

    //       // Refresh the navigation component
    //       navComponent.refresh();
    //     });
    // }

    // Get the Analytics dashboard item and update its title
    // const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
    // if (analyticsDashboardItem) {
    //   this._translocoService
    //     .selectTranslate('Analytics')
    //     .pipe(take(1))
    //     .subscribe((translation) => {
    //       // Set the title
    //       analyticsDashboardItem.title = translation;

    //       // Refresh the navigation component
    //       navComponent.refresh();
    //     });
    // }
  }
}
