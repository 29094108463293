import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';

interface BroadcastEvent {
  key: any;
  data?: any;
}

export const BroadcastKey = {
  PROGRESSING: 'Progressing',
  UPLOADING: 'Uploading',
  PROFILE: 'Profile',
  LOGS: 'Logs',
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_DATA: 'NOTIFICATION_DATA',
  NETWORK_SPEED: 'NETWORK_SPEED',
  NETWORK_CONNECTION: 'NETWORK_CONNECTION',
  SYNC_DATA: 'SYNC_DATA',
  OFFLINE_DATA_ROWS: 'OFFLINE_DATA_ROWS',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  APPCONFIG: 'APPCONFIG',
};

@Injectable({ providedIn: 'root' })
export class Broadcaster {
  private _eventBus: BehaviorSubject<BroadcastEvent>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor() {
    this._eventBus = new BehaviorSubject<BroadcastEvent>({ key: '' });
  }

  public fire(key: any, data?: any): void {
    this._eventBus.next({ key, data });
  }

  public on<T>(key: any): Observable<T> {
    return this._eventBus.asObservable().pipe(
      filter((event) => event.key === key),
      takeUntil(this.destroy$),
      map((event) => <T>event.data)
    );
  }

  public unsubscribeAll(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
