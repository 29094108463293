import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'app/app.constant';
import { environment } from 'environments/environment';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { IPostToken, ITokenResponse, IUserToken } from '../models/common.model';
import { User } from '../models/users.model';
import { LocalStorageService } from '../services/localStorage.service';
import { ProfileService } from '../services/profile.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _profileService: ProfileService,
    private _localStorageService: LocalStorageService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  public set token(token: IUserToken) {
    this._localStorageService.store(AppConstant.appStorage.userToken, token);
  }

  public get token(): IUserToken {
    return (this._localStorageService.retrieve(AppConstant.appStorage.userToken) as IUserToken) || {};
  }

  public get isAuthenticated(): boolean {
    return !!this.token.accessToken;
    // TODO: check more
    // return !!accessToken && !!refreshToken && !!this.currentUser && !!this.currentUser.id; // if is first log in then not authenticate
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  public forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  public resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param data
   */
  public signInMS(data: any): Observable<any> {
    // TODO: define type
    if (this._authenticated) {
      // Throw error, if the user is already logged in
      return of('User is already logged in.');
    }

    const postToken: IPostToken | string = {
      redirectUri: data.redirectUri,
      code: data.code,
      grantType: 'SSO',
      client: 'web',
    };
    return this._httpClient.post(`${AppConstant.domain}/api/v1/token`, postToken).pipe(
      switchMap((response: ITokenResponse) => {
        // Store the access token in the local storage
        this.token = {
          accessToken: response.token,
          refreshToken: response.refreshToken,
        };

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        const userInfo = new User(response);
        this._profileService.mergeUserInfo(userInfo);
        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   *
   * Sign in
   *
   * @param data
   */
  public signInWithCredentials(credentials: any): Observable<any> {
    // TODO: define type
    if (this._authenticated) {
      return of('User is already logged in.');
    }

    const data = {
      username: credentials.email,
      password: credentials.password,
      grantType: 'AD',
      client_id: AppConstant.clientId,
      client_secrect: AppConstant.clientSecret,
      requestVerificationToken: credentials.requestVerificationToken,
    };
    return this._httpClient.post(`${AppConstant.apiDomain}/v1/token`, data).pipe(
      switchMap((response: ITokenResponse) => {
        // Store the access token in the local storage
        this.token = {
          accessToken: response.token,
          refreshToken: response.refreshToken,
        };

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        const userInfo = new User(response);
        this._profileService.mergeUserInfo(userInfo);
        // get user detail
        // this._userService.get();

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  public signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.token.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            this.token.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          const userInfo = new User(response);
          this._profileService.mergeUserInfo(userInfo);

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  public signOut(): Observable<any> {
    // Remove the access token from the local storage
    this.clearToken();
    this._localStorageService.clearAll();
    this._router.navigate(['sign-in']);
    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  public signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  public unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  public check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.token.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.token.accessToken)) {
    //   return of(false);
    // }
    return of(this.isAuthenticated);
    // If the access token exists, and it didn't expire, sign in using it
    // return this.signInUsingToken();
  }
  public checkPermission(permissions: string | string[]): boolean {
    if (!this._profileService.user) {
      return false;
    }
    const userPermissions = (this._profileService.user.permissions || '').split(',');
    let permissionsArr = [];
    if (!permissions) {
      return true;
    } else if (typeof permissions === 'string') {
      permissionsArr = permissions.split(',');
    } else if (permissions instanceof Array) {
      permissionsArr = permissions;
    }
    // Find permission that user match
    return permissionsArr.some((p) => userPermissions.indexOf(p) >= 0);
  }

  public clearToken(): void {
    this._localStorageService.clear(AppConstant.appStorage.userToken);
    // Set the authenticated flag to false
    this._authenticated = false;
  }

  public getAntiforgerytoken(): Observable<HttpResponse<{ antiForgeryToken: string }>> {
    return this._httpClient.get<any>(`${AppConstant.apiDomain}/security/antiforgerytoken`, { observe: 'response', withCredentials: true });
  }

  public buildSignInUrl(redirectUri: string): string {
    const azureAuthorizeUrl = environment.azureAuthorizeUrl;
    const clientId = AppConstant.clientId;
    const scope = 'User.Read';
    redirectUri = redirectUri ?? window.location.origin + '/sign-in';
    const azureLoginUrl = `${azureAuthorizeUrl}/authorize?
    client_id=${clientId}
    &response_type=code
    &redirect_uri=${redirectUri}
    &response_mode=query&scope=${scope}`;
    return azureLoginUrl;
  }
}
