import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Util } from '../services/util.service';
import { AppConstant, ContentType } from 'app/app.constant';
import { IRequestOptions } from '../models/api.model';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpClient {
  // public refreshTokenSubject: Subject<string>;
  constructor(private http: HttpClient, private _util: Util) {}

  /**
   * GET request
   */
  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.intercept<T>(this.http.get<T>(AppConstant.apiDomain + endPoint, options));
  }

  /**
   * POST request
   */
  public post<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
    if (options && options.noIntercept) {
      return this.http.post<T>(endPoint, params, options);
    } else {
      return this.intercept<T>(this.http.post<T>(AppConstant.apiDomain + endPoint, params, options));
    }
  }

  /**
   * PUT request
   */
  public put<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
    if (options && options.noIntercept) {
      return this.http.put<T>(endPoint, params, options);
    } else {
      return this.intercept<T>(this.http.put<T>(AppConstant.apiDomain + endPoint, params, options));
    }
  }

  /**
   * DELETE request
   */
  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.intercept<T>(this.http.delete<T>(AppConstant.apiDomain + endPoint, options));
  }

  /**
   * Patch request
   */
  public patch<T>(endPoint: string, body?: object, options?: IRequestOptions): Observable<T> {
    if (options && options.noIntercept) {
      return this.http.patch<T>(endPoint, body, options);
    } else {
      return this.intercept<T>(this.http.patch<T>(AppConstant.apiDomain + endPoint, body, options));
    }
  }

  public intercept<T>(observable: Observable<T>): Observable<T> {
    return new Observable<T>((subscriber) => {
      observable.subscribe({
        next: (data) => {
          // subscribe
          subscriber.next(data);
        },
        error: (err) => {
          if ((err.status === 400 || err.status === 404) && err.error && err.error.errorMessages) {
            this._util.showError(err.error);
          } else if (err.status === 406) {
            this._util.showError('TOKEN_EXPIRED');
          } else if (err.status === 401) {
            this._util.showError('NOT_PERMISSION');
          } else if (err.status === 404) {
            this._util.showError('NOT_FOUND');
          } else if (err.error) {
            this._util.showError(err.error);
          } else if (err.message) {
            this._util.showError(err.message);
          } else {
            this._util.showError({ message: 'Network Error' });
          }
          subscriber.error(err);
        },
        complete: () => {
          // complete
          subscriber.complete();
        },
      });
    });
  }

  public transformImportExportResponse<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
      map((resp: any) => {
        const contentType = resp.headers.get('content-type');
        if (contentType !== ContentType.json) {
          resp.body = new Blob([resp.body], { type: contentType });
          return resp;
        } else {
          resp.body = this._util.parseArrayBufferToJson(resp.body);
          return resp;
        }
      }),
      catchError((error: any) => {
        error.error = this._util.parseArrayBufferToJson(error.error);
        return throwError(() => error);
      })
    );
  }

  /**
   * POST request import
   */
  public postImport<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
    if (options && options.noIntercept) {
      return this.http.post<T>(endPoint, params, options);
    } else {
      return this.intercept<T>(this.transformImportExportResponse<T>(this.http.post<T>(AppConstant.apiDomain + endPoint, params, options)));
    }
  }

  public getExport<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.intercept<T>(this.transformImportExportResponse<T>(this.http.get<T>(AppConstant.apiDomain + endPoint, options)));
  }
}
