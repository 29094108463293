import { Injectable } from '@angular/core';
import { CustomHttpClient } from 'app/core/http/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class DateTimeService {
  private apiPath: string = '/v1/DataSources';
  private currentDateTimePath: string = '/CurrentDateTime';
  constructor(private _http: CustomHttpClient) {}

  public getCurrentDateTimeDS(): Observable<any> {
    return this._http.get<any>(`${this.apiPath}${this.currentDateTimePath}`);
  }
}
