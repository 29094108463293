import { DatePipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AppConstant } from 'app/app.constant';
import { User, UserProfile } from 'app/core/models/users.model';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { ProfileService } from 'app/core/services/profile.service';
import { LanguagesComponent } from 'app/core/ui-modules/languages/languages.component';
import { MessagesComponent } from 'app/core/ui-modules/messages/messages.component';
import { NotificationsComponent } from 'app/core/ui-modules/notifications/notifications.component';
import { QuickChatComponent } from 'app/core/ui-modules/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/core/ui-modules/search/search.component';
import { ShortcutsComponent } from 'app/core/ui-modules/shortcuts/shortcuts.component';
import { UserComponent } from 'app/core/ui-modules/user/user.component';
import { DateTimeService } from 'app/modules/admin/master-data/date-time/date-time.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [DateTimeService],
  imports: [
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    NotificationsComponent,
    UserComponent,
    NgIf,
    MatIconModule,
    MatButtonModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    RouterOutlet,
    QuickChatComponent,
  ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  public isScreenSmall: boolean;
  public navigation: Navigation;
  public user: UserProfile = new UserProfile();
  public currentDateTime: string;
  public currentGMTOffset: string;
  public interval: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _userService: ProfileService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _dateSourcesService: DateTimeService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  public get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.updateDateTime(); // Gọi hàm cập nhật ban đầu
    this.startInterval();
    this._userService.get().subscribe((resp: UserProfile) => {
      this.user = new UserProfile(resp);
    });
    // Subscribe to navigation data
    this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
      this.navigation = navigation;
    });

    // Subscribe to the user service
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes('md');
    });
  }
  // updateDateTime
  private updateDateTime(): void {
    const datePipe = new DatePipe('en-US');
    this._dateSourcesService
      .getCurrentDateTimeDS()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.currentDateTime = datePipe.transform(res.currentDateTime, AppConstant.format.dataTime);
        this.currentGMTOffset = res.gmtOffset;
      });
  }
  private startInterval(): void {
    this.interval = window.setInterval(() => {
      this.updateDateTime();
    }, AppConstant.count.valueSecond_30);
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    window.clearInterval(this.interval)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  public toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
