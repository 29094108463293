import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  public store(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public retrieve(key: string): any {
    const value = localStorage.getItem(key);
    if (value && typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    }
    return value;
  }

  public clear(key: string): void {
    localStorage.removeItem(key);
  }

  public clearAll(): void {
    localStorage.clear();
  }
}
