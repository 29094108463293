import { Injectable } from '@angular/core';
import { map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { IChangePassword, IUser, User } from '../models/users.model';
import { AppConstant } from 'app/app.constant';
import { IApiResponse } from '../models/api.model';
import { CustomHttpClient } from '../http/http.service';
import { LocalStorageService } from './localStorage.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private apiName: string = '/v1/Users';

  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  /**
   * Constructor
   */
  constructor(private _http: CustomHttpClient, private _localStorageService: LocalStorageService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  public set user(value: User) {
    // Store the value
    this._localStorageService.store(AppConstant.appStorage.userInfo, value);
    this._user.next(value);
  }
  public get user(): User {
    let user = this._localStorageService.retrieve(AppConstant.appStorage.userInfo) as User;
    if (!user) {
      user = new User();
    }
    return user;
  }

  public get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public mergeUserInfo(value: User): void {
    this.user = Object.assign(this.user, value);
  }

  public clearUserInfo(): void {
    this._localStorageService.clear(AppConstant.appStorage.userInfo);
  }

  /**
   * Get the current logged in user data
   */
  public get(): Observable<IUser> {
    return this._http.get<IUser>(`${this.apiName}/profile`).pipe(
      tap((user) => {
        this.mergeUserInfo(user);
        this._user.next(this.user);
      })
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  public updateProfile(user: IUser): Observable<any> {
    return this._http.put(`${this.apiName}/edit-profile`, user).pipe(
      map(() => {
        this._user.next(Object.assign(this.user, user));
      })
    );
  }

  public updateStatus(status: string): Observable<any> {
    return of({}).pipe(
      map(() => {
        this._user.next(Object.assign(this.user, { status }));
      })
    );
  }

  public changePassword(data: IChangePassword): Observable<IApiResponse> {
    return this._http.patch(`${this.apiName}/change-password`, data);
  }
}
