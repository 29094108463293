import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';

export const PermissionGuard: CanActivateFn | CanActivateChildFn = (route: ActivatedRouteSnapshot) => {
  const authService: AuthService = inject(AuthService);
  if (!authService.checkPermission(route.data['permission'])) {
    processPermission();
  }
  return true;
};

function processPermission(): boolean {
  const router: Router = inject(Router);
  router.navigate(['auth', 'not-permission']);
  return false;
}
