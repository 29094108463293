import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { cloneDeep } from 'lodash';
import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { FuseNavigationData } from './navigation-data';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private readonly _compactNavigation: FuseNavigationItem[] = new FuseNavigationData(this._authService).compactNavigation;
  public _defaultNavigation: FuseNavigationItem[] = new FuseNavigationData(this._authService).defaultNavigation;
  private readonly _futuristicNavigation: FuseNavigationItem[] = new FuseNavigationData(this._authService).futuristicNavigation;
  private readonly _horizontalNavigation: FuseNavigationItem[] = new FuseNavigationData(this._authService).horizontalNavigation;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, public _authService: AuthService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  public get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  public get(): Observable<Navigation> {
    // Fill horizontal navigation children using the default navigation
    // this._horizontalNavigation.forEach((horizontalNavItem) => {
    //   this._defaultNavigation.forEach((defaultNavItem) => {
    //     if (defaultNavItem.id === horizontalNavItem.id) {
    //       horizontalNavItem.children = cloneDeep(defaultNavItem.children);
    //     }
    //   });
    // });
    return of({
      compact: cloneDeep(this._compactNavigation),
      default: cloneDeep(this._defaultNavigation),
      futuristic: cloneDeep(this._futuristicNavigation),
      horizontal: cloneDeep(this._horizontalNavigation),
    }).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
    // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
    //     tap((navigation) =>
    //     {
    //         this._navigation.next(navigation);
    //     }),
    // );
  }
}
