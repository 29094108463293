export const Permission = {
  MasterData: {
    Line: {
      ViewList: 'Line.ViewList',
      Edit: 'Line.Edit',
      ViewDetail: 'Line.ViewDetail',
    },
    Product: {
      ViewList: 'Product.ViewList',
      Add: 'Product.Add',
      Edit: 'Product.Edit',
      ViewDetail: 'Product.ViewDetail',
      Delete: 'Product.Delete',
      Import: 'Product.Import',
      Export: 'Product.Export',
    },
  },
  UserManagement: {
    User: {
      ViewList: 'User.ViewList',
      Add: 'User.Add',
      Edit: 'User.Edit',
      ViewDetail: 'User.ViewDetail',
      ExportTemplateList: 'User.ExportTemplateList',
      ImportList: 'User.ImportList',
    },
  },
  Production: {
    ProductionPlanManagement: {
      ViewList: 'ProductionPlan.ViewList',
      Add: 'ProductionPlan.Add',
      Edit: 'ProductionPlan.Edit',
      ViewDetail: 'ProductionPlan.ViewDetail',
      Delete: 'ProductionPlan.Delete',
      Rework: 'ProductionPlan.Rework',
      Import: 'ProductionPlan.Import',
      Export: 'ProductionPlan.Export',
      DownloadTemplate: 'ProductionPlan.ExportTemplate',
      ExportManufactureFile: 'Export.ManufactureFile',
      ExportShippingFile: 'Export.ShippingFile',
      ExportScannedCodesByProcessOrder: 'Export.ScannedCodesByProcessOrder',
      ExportScannedCodesByBatch: 'Export.ScannedCodesByBatch',
      ExportIPCbyProcessOrder: 'Export.IPCbyProcessOrder',
      ExportIPCbyBatch: 'Export.IPCbyBatch',
    },
    BarcodeFile: {
      ViewList: 'BarcodeFile.ViewList',
      Import: 'BarcodeFile.Import',
      Download: 'BarcodeFile.Download',
      Delete: 'BarcodeFile.Delete',
    },
    Barcode: {
      ViewList: 'Barcode.ViewList',
    },
  },
  Reporting: {
    ScannedCode: {
      View: 'ScannedCode.View',
      ExportScannedPopup: 'ScannedLog.ExportScannedPopup',
    },
    ErrorCode: {
      ViewError: 'ErrorCode.ViewError',
      ExportError: 'ErrorCode.ExportError',
    },
    AuditLog: {
      ViewAuditLog: 'AuditLog.ViewAuditLog',
      ExportAuditLog: 'ExportAuditLog',
    },
  },
  Configuration: {
    Setting: 'Configuration.Setting',
    Language: 'Configuration.Language',
  },
};

export interface ICommonPermission {
  add?: boolean;
  edit?: boolean;
  viewDetail?: boolean;
  delete?: boolean;
  import?: boolean;
  export?: boolean;
  downloadTemplate?: boolean;
}

export interface IPermissionPP extends ICommonPermission {
  exportManufactureFile?: boolean;
  exportShippingFile?: boolean;
  exportScannedCodesByProcessOrder?: boolean;
  exportScannedCodesByBatch?: boolean;
  rework?: boolean;
  exportIPCbyProcessOrder?: boolean;
  exportIPCbyBatch?: boolean;
}
