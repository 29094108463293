import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PermissionGuard } from './core/auth/guards/permission.guard';
import { Permission } from './core/models/permission.model';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: '/pages/home' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/pages/home' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
      { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
      { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
      { path: 'sign-in-ms', loadChildren: () => import('app/modules/auth/sign-in-ms/sign-in-ms.routes') },
      { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
      { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
      { path: 'signin-notification', loadChildren: () => import('app/modules/auth/signin-notification/signin-notification.routes') },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
      { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'enterprise',
    },
    children: [{ path: 'landing-home', loadChildren: () => import('app/modules/landing/home/home.routes') }],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // Apps
      {
        path: 'pages',
        data: {
          breadcrumb: 'NAV.APPLICATIONS',
          disabled: true,
        },
        canActivateChild: [PermissionGuard],
        children: [
          { path: 'home', loadChildren: () => import('app/modules/admin/home/home.routes') },
          // Users Managerment
          {
            path: 'user-management',
            data: {
              breadcrumb: 'NAV.USER.TITLE',
              disabled: true,
              permission: [Permission.UserManagement.User.ViewList],
            },
            children: [
              {
                path: 'users',
                data: {
                  breadcrumb: 'NAV.USER.USERS',
                },
                loadChildren: () => import('app/modules/admin/user-management/users/users.module').then((m) => m.UsersModule),
              },

              // User Roles
              {
                path: 'roles',
                data: {
                  breadcrumb: 'USER.ROLES',
                },
                loadChildren: () => import('app/modules/admin/home/home.routes'),
              },
            ],
          },
          // Master data
          {
            path: 'master-data',
            data: {
              breadcrumb: 'NAV.MASTERDATA',
              disabled: true,
              permission: [Permission.MasterData.Line.ViewList, Permission.MasterData.Product.ViewList],
            },
            children: [
              {
                path: 'factory',
                data: {
                  breadcrumb: 'NAV.FACTORY',
                },
                loadChildren: () => import('app/modules/admin/master-data/factory/factory.module').then((m) => m.FactoryModule),
              },
              {
                path: 'department',
                data: {
                  breadcrumb: 'NAV.DEPARTMENT',
                },
                loadChildren: () => import('app/modules/admin/master-data/department/department.module').then((m) => m.DepartmentModule),
              },
              {
                path: 'product',
                data: {
                  breadcrumb: 'NAV.PRODUCT.MANAGEMENT',
                  permission: [Permission.MasterData.Product.ViewList],
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/master-data/product/product.module').then((m) => m.ProductModule),
              },
              {
                path: 'line',
                data: {
                  breadcrumb: 'NAV.LINE',
                  permission: [Permission.MasterData.Line.ViewList],
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/master-data/line/line.module').then((m) => m.LineModule),
              },
            ],
          },
          //Production
          {
            path: 'production',
            data: {
              breadcrumb: 'NAV.PRODUCTION',
              disabled: true,
              permission: [
                Permission.Production.Barcode.ViewList,
                Permission.Production.BarcodeFile.ViewList,
                Permission.Production.ProductionPlanManagement.ViewList,
              ],
            },
            children: [
              // Setting
              {
                path: 'qrcodefile',
                data: {
                  breadcrumb: 'NAV.2D.BARCODEFILE',
                  permission: [Permission.Production.BarcodeFile.ViewList],
                },

                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/qr-code-file/qr-code-file.module').then((m) => m.QrCodeFileModule),
              },
              {
                path: 'qr-code',
                data: {
                  breadcrumb: 'NAV.2D.BARCODE',
                  permission: [Permission.Production.Barcode.ViewList],
                },
                loadChildren: () => import('app/modules/admin/qr-code/qr-code.module').then((m) => m.QrCodeModule),
              },
              {
                path: 'production-plan',
                data: {
                  breadcrumb: 'NAV.PRODUCT_PLAN',
                  permission: [Permission.Production.ProductionPlanManagement.ViewList],
                },
                loadChildren: () => import('app/modules/admin/production-plan/production-plan.module').then((m) => m.ProductionPlanModule),
              },
            ],
          },

          //Report
          {
            path: 'report',
            data: {
              breadcrumb: 'NAV.REPORT',
              disabled: true,
              permission: [Permission.Reporting.ErrorCode.ViewError, Permission.Reporting.ScannedCode.View, Permission.Reporting.AuditLog.ViewAuditLog],
            },
            children: [
              // scanned-code
              {
                path: 'scanned-code',
                data: {
                  breadcrumb: 'NAV.SCANNED.CODE',
                  permission: [Permission.Reporting.ScannedCode.View],
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/report/scanned-code/scanned-code.module').then((m) => m.ScannedCodeModule),
              },
              {
                path: 'error-code',
                data: {
                  breadcrumb: 'NAV.ERROR.BARCODE',
                  permission: [Permission.Reporting.ErrorCode.ViewError],
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/report/error-code/error-code.module').then((m) => m.ErrorCodeModule),
              },
              {
                path: 'operation-tracking-log',
                data: {
                  breadcrumb: 'NAV.OPERATION.TRACKING.LOG',
                },
                loadChildren: () => import('app/modules/admin/report/operation-tracking/operation-tracking.module').then((m) => m.OperationTrackingModule),
              },
              {
                path: 'audit-log',
                data: {
                  permission: [Permission.Reporting.AuditLog.ViewAuditLog],
                  breadcrumb: 'NAV.AUDIT.LOG',
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/report/audit-log/audit-log.module').then((m) => m.AuditLogModule),
              },
            ],
          },
          // Configs

          {
            path: 'configs',
            data: {
              breadcrumb: 'NAV.CONFIGURATION',
              disabled: true,
              permission: [Permission.Configuration.Setting, Permission.Configuration.Language],
            },
            children: [
              // Setting
              {
                path: 'setting',
                data: {
                  breadcrumb: 'Setting',
                  permission: [Permission.Configuration.Setting],
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/configuration/setting/setting.module').then((m) => m.SettingModule),
              },
              // Language
              {
                path: 'language',
                data: {
                  breadcrumb: 'NAV.LANGUAGE.TITLE',
                  permission: [Permission.Configuration.Language],
                },
                canActivateChild: [PermissionGuard],
                loadChildren: () => import('app/modules/admin/configuration/language/language.module').then((m) => m.LanguageModule),
              },
            ],
          },
          {
            path: 'profile',
            loadChildren: () => import('app/modules/admin/profile/profile.module').then((m) => m.ProfileModule),
          },
        ],
      },
      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () => import('app/modules/admin/error/error-404/error-404.module').then((m) => m.Error404Module),
      },
      { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
