import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from './core/services/localStorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  /**
   * Constructor
   */
  constructor(private _translocoService: TranslocoService, private _localStorageService: LocalStorageService) {
    const availableLangs: LangDefinition[] = [
      { id: 'vi', label: 'Vietnam' },
      { id: 'en', label: 'English' },
    ];
    this._translocoService.setAvailableLangs(availableLangs);
    const languageId = this._localStorageService.retrieve('lang') || 'vi' || navigator.language.substring(0, 2);
    this._translocoService.setDefaultLang(languageId);
    this._translocoService.setActiveLang(languageId);
  }
}
