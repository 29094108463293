import { FormControl } from '@angular/forms';
import { IApiListQueryParams } from './api.model';

// TODO: remove unuse field
type UserStatus = 'online' | 'away' | 'busy' | 'not-visible';
export interface IUser {
  id?: number;
  // token response
  userId?: number;
  fullName?: string;
  isFirstLogin?: boolean | string;
  isSuperAdmin?: boolean;
  permissions?: string;
  username?: string;
  userRoles?: string;
  // profile response
  position?: string;
  lastLoginDateUtc?: string;
  // user detail response
  email?: string;
  departmentId?: number;
  userRoleIds?: number[];
  isActive?: boolean;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  UpdatedDate?: string;
}
export class User implements IUser {
  public id?: number;
  // token response
  public userId?: number;
  public fullName?: string;
  public isLinkedAD?: boolean | string;
  public isSuperAdmin?: boolean;
  public permissions?: string;
  public username?: string;
  public userRoles?: string;
  // profile response
  public email?: string;
  public position?: string;
  public lastLoginDateUtc?: string;
  // user detail response
  public departmentId?: number;
  public userRoleIds?: number[];
  public isActive?: boolean;
  public createdBy?: string;
  public createdDate?: string;
  public updatedBy?: string;
  public UpdatedDate?: string;
  // app
  public avatar?: string;
  public status?: UserStatus;
  public departmentName?: string;
  public userRoleNames?: number[];
  constructor(user?) {
    this.id = user?.id;
    this.userId = user?.userId;
    this.fullName = user?.fullName;
    this.isLinkedAD = user?.isLinkedAD;
    this.isSuperAdmin = user?.isSuperAdmin;
    this.permissions = user?.permissions;
    this.username = user?.username || user?.userName;
    this.userRoles = user?.userRoles;
    this.email = user?.email;
    this.position = user?.position;
    this.lastLoginDateUtc = user?.lastLoginDateUtc;
    this.departmentId = user?.departmentId || null;
    this.userRoleIds = user?.userRoleIds || [];
    this.isActive = user?.isActive;
    this.createdBy = user?.createdBy;
    this.createdDate = user?.createdDate;
    this.updatedBy = user?.updatedBy;
    this.UpdatedDate = user?.UpdatedDate;
    this.avatar = user?.avatar;
    this.departmentName = user?.departmentName;
    this.userRoleNames = user?.userRoleNames;
  }
}

export interface IChangePassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}
export type ChangePasswordFormModel = {
  oldPassword: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
  twoStep?: FormControl<boolean>;
  askPasswordChange?: FormControl<boolean>;
};

export interface IUserFilterParams extends IApiListQueryParams {
  userRoleIds?: string;
  departments?: string;
  languageCulture?: string;
}

export interface IUserProfile {
  id?: number;
  username?: string;
  fullName?: string;
  email?: string;
  avatar?: string;
  position?: string;
  departmentName?: string;
  userRoleNames?: number[];
  lastLoginDateUtc?: string;
}
export class UserProfile implements IUserProfile {
  public id?: number;
  public username?: string;
  public fullName?: string;
  public avatar?: string;
  public email?: string;
  public position?: string;
  public departmentName?: string;
  public userRoleNames?: number[];
  public lastLoginDateUtc?: string;
  constructor(user?) {
    this.id = user?.id;
    this.fullName = user?.fullName;
    this.username = user?.username || user?.userName;
    this.email = user?.email;
    this.position = user?.position;
    this.lastLoginDateUtc = user?.lastLoginDateUtc;
    this.departmentName = user?.departmentName;
    this.userRoleNames = user?.userRoleNames;
  }
}
