import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppConstant } from 'app/app.constant';
import { AuthService } from 'app/core/auth/auth.service';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, tap, throwError } from 'rxjs';
import { ITokenResponse } from '../models/common.model';
import { BroadcastKey, Broadcaster } from '../services/broadcaster.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const anonymousUrls: string[] = [
    '/token',
    'assets/',
    'https://onesignal.com/api/v1/notifications',
    '/notification',
    '/security/antiforgerytoken',
    '/tenants',
  ];
  const authService = inject(AuthService);
  const broadcaster = inject(Broadcaster);
  const http = inject(HttpClient);
  let isRefreshing = false;
  const refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  const addTokenHeader = (request: HttpRequest<unknown>, token: string): HttpRequest<unknown> => {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token).set('X-Timezone-Offset', '' + new Date().getTimezoneOffset()) });
  };

  // Clone the request object
  // let newReq = req.clone();

  // Request
  //
  // If the access token didn't expire, add the Authorization header.
  // We won't add the Authorization header if the access token expired.
  // This will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  // if (authService.token.accessToken && !AuthUtils.isTokenExpired(authService.token.accessToken)) {
  //   newReq = req.clone({
  //     headers: req.headers.set('Authorization', 'Bearer ' + authService.token.accessToken),
  //   });
  // }
  const token = authService.token;
  if (anonymousUrls.every((url) => req.url.toLowerCase().indexOf(url.toLowerCase()) < 0)) {
    if (!authService.isAuthenticated) {
      // console.log('BearerInterceptor logout', req.url.toLowerCase());
      authService.signOut();
      return next(req);
    } else {
      let authReq = req;
      authReq = addTokenHeader(req, token.accessToken);

      const startingTime = Date.now();
      return next(authReq).pipe(
        tap((evt) => {
          if (evt instanceof HttpResponse) {
            const timePassed = (Date.now() - startingTime) / 1000; // seconds
            const size = (evt.headers ? +evt.headers.get('Content-Length') || 0 : 0) / 1000; // KB
            broadcaster.fire(BroadcastKey.NETWORK_SPEED, (size / timePassed).toFixed(2));
          }
        }),
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            anonymousUrls.every((url) => req.url.toLowerCase().indexOf(url.toLowerCase()) < 0) &&
            error.status === 401
          ) {
            if (!isRefreshing) {
              isRefreshing = true;
              refreshTokenSubject.next(null);
              const userToken = authService.token;
              const refreshToken = userToken ? userToken.refreshToken : null;

              if (refreshToken) {
                return http.get(`${AppConstant.apiDomain}/token/${refreshToken}`).pipe(
                  switchMap((responseData: ITokenResponse) => {
                    isRefreshing = false;
                    if (!responseData.token || !responseData.refreshToken) {
                      authService.signOut();
                    }
                    authService.token = {
                      accessToken: responseData.token,
                      refreshToken: responseData.refreshToken,
                    };
                    refreshTokenSubject.next(responseData.token);

                    return next(addTokenHeader(authReq, responseData.token));
                  }),
                  catchError((err) => {
                    isRefreshing = false;
                    authService.signOut();
                    return throwError(() => err);
                  })
                );
              } else {
                isRefreshing = false;
                authService.signOut();
              }
            }

            return refreshTokenSubject.pipe(
              filter((token) => token !== null),
              take(1),
              switchMap((token) => next(addTokenHeader(authReq, token)))
            );
          }
          // else if (err.error.errorMessages[0] === 'Permission.NotPermission' || err.error.errorMessages[0] === 'permission.notpermission')
          return throwError(() => error);
        })
      );
    }
  }

  // Response
  return next(req).pipe(
    catchError((error) => {
      // Catch "401 Unauthorized" responses
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Sign out
        authService.signOut();

        // Reload the app
        location.reload();
      }

      return throwError(error);
    })
  );
};
