/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { AuthService } from '../auth/auth.service';
import { Permission } from '../models/permission.model';

export class FuseNavigationData {
  public defaultNavigation: FuseNavigationItem[] = [];
  public compactNavigation: FuseNavigationItem[] = [];
  public futuristicNavigation: FuseNavigationItem[] = [];
  public horizontalNavigation: FuseNavigationItem[] = [];
  constructor(public _ath: AuthService) {
    this.defaultNavigation = [
      {
        id: 'apps',
        title: 'APP_NAME',
        subtitle: 'NAV.APP.SUBTITLE',
        translateTitleKey: 'NAV.APP',
        translateSubtitleKey: 'APP',
        type: 'group',
        icon: 'heroicons_outline:home',
        classes: { wrapper: 'mt-2' },
        children: [
          {
            id: 'apps.home',
            title: 'Home',
            translateTitleKey: 'Home',
            type: 'basic',
            icon: 'home',
            link: '/pages/home',
          },
          {
            id: 'apps.user-management',
            title: 'NAV.USER.TITLE',
            translateTitleKey: 'NAV.USER.TITLE',
            type: 'collapsable',
            icon: 'heroicons_outline:users',
            hidden: () => !this._ath.checkPermission([Permission.UserManagement.User.ViewList]),
            children: [
              {
                id: 'apps.users',
                title: 'NAV.USER.USERS',
                translateTitleKey: 'NAV.USER.USERS',
                type: 'basic',
                link: '/pages/user-management/users',
              },
              // {
              //   id: 'apps.roles',
              //   title: 'NAV.USER.ROLES',
              //   translateTitleKey: 'NAV.USER.ROLES',
              //   type: 'basic',
              //   link: '/pages/user-management/roles',
              // },
            ],
          },
          {
            id: 'apps.masterdata',
            title: 'NAV.MASTERDATA',
            translateTitleKey: 'NAV.MASTERDATA',
            type: 'collapsable',
            icon: 'heroicons_outline:circle-stack',
            hidden: () => !this._ath.checkPermission([Permission.MasterData.Line.ViewList, Permission.MasterData.Product.ViewList]),
            children: [
              // {
              //   id: 'apps.masterdata.factory',
              //   title: 'NAV.FACTORY',
              //   translateTitleKey: 'NAV.FACTORY',
              //   type: 'basic',
              //   link: '/pages/master-data/factory',
              // },
              // {
              //   id: 'department',
              //   title: 'NAV.DEPARTMENT',
              //   translateTitleKey: 'NAV.DEPARTMENT',
              //   type: 'basic',
              //   link: '/pages/master-data/department',
              // },
              {
                id: 'line',
                title: 'NAV.LINE',
                translateTitleKey: 'NAV.LINE',
                type: 'basic',
                link: '/pages/master-data/line',
                hidden: () => !this._ath.checkPermission([Permission.MasterData.Line.ViewList]),
              },
              {
                id: 'product',
                title: 'NAV.PRODUCT.MANAGEMENT',
                translateTitleKey: 'NAV.PRODUCT.MANAGEMENT',
                type: 'basic',
                link: '/pages/master-data/product',
                hidden: () => !this._ath.checkPermission([Permission.MasterData.Product.ViewList]),
              },
            ],
          },
          {
            id: 'apps.production',
            translateTitleKey: 'NAV.PRODUCTION',
            title: 'NAV.PRODUCTION',
            type: 'collapsable',
            icon: 'feather:package',
            hidden: () =>
              !this._ath.checkPermission([
                Permission.Production.BarcodeFile.ViewList,
                Permission.Production.Barcode.ViewList,
                Permission.Production.ProductionPlanManagement.ViewList,
              ]),

            children: [
              {
                id: 'apps.qrcode-file',
                title: 'NAV.2D.BARCODEFILE"',
                translateTitleKey: 'NAV.2D.BARCODEFILE',
                type: 'basic',
                link: '/pages/production/qrcodefile',
                hidden: () => !this._ath.checkPermission([Permission.Production.BarcodeFile.ViewList]),
              },
              {
                id: 'apps.qr-code',
                title: 'NAV.2D.BARCODE"',
                translateTitleKey: 'NAV.2D.BARCODE',
                type: 'basic',
                link: '/pages/production/qr-code',
                hidden: () => !this._ath.checkPermission([Permission.Production.Barcode.ViewList]),
              },
              {
                id: 'apps.production-plan',
                title: 'NAV.PRODUCT_PLAN"',
                translateTitleKey: 'NAV.PRODUCT_PLAN',
                type: 'basic',
                link: '/pages/production/production-plan',
                hidden: () => !this._ath.checkPermission([Permission.Production.ProductionPlanManagement.ViewList]),
              },
            ],
          },
          {
            id: 'apps.report',
            translateTitleKey: 'NAV.REPORT',
            title: 'NAV.REPORT',
            type: 'collapsable',
            icon: 'heroicons_outline:clipboard-document-list',
            hidden: () =>
              !this._ath.checkPermission([
                Permission.Reporting.ScannedCode.View,
                Permission.Reporting.ErrorCode.ViewError,
                Permission.Reporting.AuditLog.ViewAuditLog,
              ]),

            children: [
              {
                id: 'apps.scanned-code',
                title: 'NAV.SCANNED.CODE"',
                translateTitleKey: 'NAV.SCANNED.CODE',
                type: 'basic',
                link: '/pages/report/scanned-code',
                hidden: () => !this._ath.checkPermission([Permission.Reporting.ScannedCode.View]),
              },
              {
                id: 'apps.error-code',
                title: 'NAV.ERROR.BARCODE"',
                translateTitleKey: 'NAV.ERROR.BARCODE',
                type: 'basic',
                link: '/pages/report/error-code',
                hidden: () => !this._ath.checkPermission([Permission.Reporting.ErrorCode.ViewError]),
              },
              {
                id: 'apps.operation-tracking-log',
                title: 'NAV.OPERATION.TRACKING.LOG',
                translateTitleKey: 'NAV.OPERATION.TRACKING.LOG',
                type: 'basic',
                link: '/pages/report/operation-tracking-log',
              },
              {
                id: 'apps.audit-log',
                title: 'NAV.AUDIT.LOG',
                translateTitleKey: 'NAV.AUDIT.LOG',
                type: 'basic',
                link: '/pages/report/audit-log',
                hidden: () => !this._ath.checkPermission([Permission.Reporting.AuditLog.ViewAuditLog]),
              },
            ],
          },
          {
            id: 'apps.configuration',
            translateTitleKey: 'NAV.CONFIGURATION',
            title: 'NAV.CONFIGURATION',
            type: 'collapsable',
            icon: 'heroicons_outline:cog',
            hidden: () => !this._ath.checkPermission([Permission.Configuration.Setting, Permission.Configuration.Language]),
            children: [
              {
                id: 'apps.configuration.setting',
                title: 'NAV.SETTING.TITLE',
                translateTitleKey: 'NAV.SETTING.TITLE',
                type: 'basic',
                hidden: () => !this._ath.checkPermission([Permission.Configuration.Setting]),
                link: '/pages/configs/setting',
              },
              {
                id: 'apps.configuration.language',
                title: 'NAV.LANGUAGE.TITLE',
                translateTitleKey: 'NAV.LANGUAGE.TITLE',
                type: 'basic',
                link: '/pages/configs/language',
                hidden: () => !this._ath.checkPermission([Permission.Configuration.Language]),
              },
            ],
          },
        ],
      },
    ];
  }
}
