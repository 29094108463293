import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { saveAs } from 'file-saver';
import { FuseConfirmationConfig } from '@fuse/services/confirmation';

@Injectable({
  providedIn: 'root',
})
export class Util {
  constructor(private _translocoService: TranslocoService, private _toastrService: ToastrService) {}

  public CreateFuseConfirmationConfig(): FuseConfirmationConfig {
    return {
      message: this._translocoService.translate('CONFIRM.MESSAGE'),
      title: this._translocoService.translate('CONFIRM.ACTION'),
      actions: {
        confirm: {
          show: true,
          label: this._translocoService.translate('CONFIRM'),
          color: 'warn',
        },
        cancel: {
          show: true,
          label: this._translocoService.translate('CANCEL'),
        },
      },
    };
  }

  public getFullRoutePath(suffix, route: ActivatedRouteSnapshot): string {
    if (route.routeConfig && route.routeConfig.path) {
      // If the path not empty
      suffix = `${route.routeConfig.path}/${suffix}`;
    }
    if (route.parent) {
      // If it still has parent
      return this.getFullRoutePath(suffix, route.parent);
    }
    return '/' + suffix;
  }

  public getFullRoutePathByActivatedRoute(suffix, route: ActivatedRoute): string {
    if (route.routeConfig && route.routeConfig.path) {
      // If the path not empty
      suffix = `${route.routeConfig.path}/${suffix}`;
    }
    if (route.parent) {
      // If it still has parent
      return this.getFullRoutePathByActivatedRoute(suffix, route.parent);
    }
    return '/' + suffix;
  }

  public getLastActivatedRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  /**
   * transformRequestHandler
   */
  public transformRequestHandler(obj): string {
    const str: string[] = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  public processErrorMessage(error): string {
    let errorMessage = '';
    if (error.message) {
      errorMessage = this._translocoService.translate(error.message);
    } else if (error.error_description) {
      errorMessage = this._translocoService.translate(error.error_description);
    } else if (error.errorMessages && error.errorMessages instanceof Array) {
      error.errorMessages.forEach((e: string, i: number) => {
        errorMessage += (e ? this._translocoService.translate(e, error.errorParams[i] ? error.errorParams[i] : null) : '') + '<br>';
      });
    } else if (typeof error === 'string') {
      errorMessage = this._translocoService.translate(error);
    } else {
      errorMessage = this._translocoService.translate('System error, please contact administrator for support');
    }
    return errorMessage;
  }

  public showError(error, title = 'Error'): void {
    this._toastrService.error(this.processErrorMessage(error), title, { timeOut: 10000, enableHtml: true });
  }

  public getFilename(url): string {
    if (!url) {
      return null;
    }
    return url.substring(url.lastIndexOf('/') + 1);
  }

  public downloadFile(fileUrl): void {
    const filename = this.getFilename(fileUrl);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public indexToAlphabet(n): string {
    const a = 'A'.charCodeAt(0);
    const z = 'Z'.charCodeAt(0);
    const len = z - a + 1;
    let s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + a) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }

  /**
   * input value can be: 00:00, 1_:__, _2:34, __:56, ...
   */
  public convertToMinute(value): number {
    value = value ? value.replace(/_/g, '0') : '';
    if (value === '') {
      return null;
    }
    const colonIndex = value.indexOf(':');
    let minute = null;
    if (colonIndex >= 0) {
      minute = +value.substr(0, colonIndex) + +value.substr(colonIndex + 1, 2) / 60;
    } else {
      minute = +value;
    }
    return minute;
  }

  public downloadFileBase64(bytes: string, fileName: string): void {
    const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    const link = document.createElement('a');
    link.href = mediaType + bytes;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public groupBy(xs: any[], key): any[] {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public calcYScaleMax(values, rate = 0.2): number {
    const max = Math.max.apply(Math, [...values]);
    const min = Math.min.apply(Math, [...values]);
    const range = max - min;
    return max + Math.abs(range) * rate;
  }

  public calcYScaleMin(values, rate = 0.2): number {
    const max = Math.max.apply(Math, [...values]);
    const min = Math.min.apply(Math, [...values]);
    const range = max - min;
    if (min >= 0) {
      if (min - Math.abs(range) * rate >= 0) {
        return min - Math.abs(range) * rate;
      } else {
        return 0;
      }
    } else {
      return min - Math.abs(range) * rate;
    }
  }

  public getRandomInt(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * function return random color, ex: "#019ABF"
   * See: http://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
   */
  public getRandomColor(length = 1): string[] {
    const goldenRatioConjugate = 0.618033988749895;
    const hue2rgb = (p, q, t): number => {
      if (t < 0) {
        t += 1;
      }
      if (t > 1) {
        t -= 1;
      }
      if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
      }
      if (t < 1 / 2) {
        return q;
      }
      if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
      }
      return p;
    };
    const hslToRgb = (h, s, l): string => {
      let r;
      let g;
      let b;
      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }
      return '#' + Math.round(r * 255).toString(16) + Math.round(g * 255).toString(16) + Math.round(b * 255).toString(16);
    };

    const result = [];
    for (let i = 0; i < length; i++) {
      let hue = Math.random();
      hue += goldenRatioConjugate;
      hue %= 1;
      result.push(hslToRgb(hue, 0.5, 0.6)); // default 0.5, 0.6
    }
    return result;
  }

  public padZero(str, len?): string {
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  public invertColor(hex, bw = true): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    let r: number | string = parseInt(hex.slice(0, 2), 16);
    let g: number | string = parseInt(hex.slice(2, 4), 16);
    let b: number | string = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }

  public compareArray(arr1: (string | number)[] = [], arr2: (string | number)[] = []): boolean {
    if (arr1.sort().join(',') === arr2.sort().join(',')) {
      return true;
    }
    return false;
  }

  public getFileNameFromPath(urlPath: string = ''): string {
    return urlPath.substring(urlPath.lastIndexOf('/') + 1);
  }

  public createFile(response: any, fileName: string = ''): void {
    const contentDisposition = response.headers.headers.get('content-disposition')[0];
    const fileNameAnsi = contentDisposition.substring(contentDisposition.lastIndexOf('filename=') + 9, contentDisposition.indexOf('filename*') - 2);
    const fileNameUnicode = contentDisposition.substring(contentDisposition.lastIndexOf('filename*') + 17, contentDisposition.length);
    fileName = fileName || decodeURIComponent(fileNameUnicode) || fileNameAnsi;
    fileName = fileName.replace(/\"/g, '');
    saveAs(response.body, fileName);
  }

  public getCookie(cname): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public filterArrayByString(mainArr: any[], searchText: string): any[] {
    if (searchText === '') {
      return mainArr;
    }
    searchText = searchText.toLowerCase();
    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
  }

  public searchInObj(itemObj: any, searchText: string): boolean {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }
      const value = itemObj[prop];
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }
      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  public searchInArray(arr: any[], searchText: string): boolean {
    for (const value of arr) {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }
      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  public searchInString(value: string, searchText: string): boolean {
    return value.toLowerCase().includes(searchText);
  }

  public getNewDate(value?: Date): Date {
    const currentDate = value ?? new Date();
    const options = { timeZone: 'Asia/Ho_Chi_Minh' };
    const currentDateTimeVi = currentDate.toLocaleString('en-US', options);
    return new Date(currentDateTimeVi);
  }

  public parseArrayBufferToJson(value?: ArrayBuffer): JSON {
    return JSON.parse(new TextDecoder().decode(value));
  }
}
